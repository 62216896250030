import { HTMLAttributeReferrerPolicy } from 'react'

import { encodeQueryString } from '../queryString'

const DEFAULT_OVERLAYS =
  '&o[0][ot]=sma&o[0][op]=50&o[0][oc]=FF8F33C6&o[1][ot]=sma&o[1][op]=200&o[1][oc]=DCB3326D&o[2][ot]=patterns&o[2][op]=&o[2][oc]=000'

export function getHoverChartUrl(
  ticker: string,
  escaped: boolean = false,
  timeframe = FinvizSettings.hoverCharts?.stock?.timeframe,
  dateRange = FinvizSettings.hoverCharts?.stock?.dateRange
): string {
  const theme = FinvizSettings.hasDarkTheme ? 'd' : undefined
  const overlays = FinvizSettings.hasUserPremium && FinvizSettings.hoverCharts?.stock?.patterns ? DEFAULT_OVERLAYS : ''
  const query = encodeQueryString({
    cs: 'm',
    t: ticker,
    tf: timeframe ?? 'd',
    ct: 'candle_stick',
    r: dateRange,
    tm: theme,
  })

  let imgUrl = `${FinvizSettings.nodeChartsDomain}/chart.ashx?${query}${overlays}`

  if (escaped) {
    // "Escaping" [ and ] because of how boxover.js works
    imgUrl = imgUrl.replace(/\[/g, '[[').replace(/]/g, ']]')
  }
  return imgUrl
}

export function getHoverChartDimensions() {
  switch (FinvizSettings.hoverCharts?.stock?.timeframe) {
    case 'i3':
    case 'i15':
      return { width: 584, height: 180 }
    case 'i5':
      return { width: 376, height: 180 }
    case 'd':
      if (FinvizSettings.hoverCharts?.stock?.patterns) return { width: 464, height: 230 }
      return { width: 324, height: 180 }
    default:
      return { width: 324, height: 180 }
  }
}

export function getReferrerPolicy(): HTMLAttributeReferrerPolicy {
  return 'no-referrer-when-downgrade'
}

export function getHoverChartImg(
  ticker: string,
  escaped: boolean = false,
  timeframe = FinvizSettings.hoverCharts?.stock?.timeframe,
  dateRange?: string
) {
  const chartSize = getHoverChartDimensions()
  const referrerPolicy = getReferrerPolicy()
  const url = getHoverChartUrl(ticker, escaped, timeframe, dateRange)
  const srcSet = getSrcSet(url)
  const srcsetString = srcSet?.length ? ` srcset='${srcSet}'` : ''

  return {
    img: `<img referrerPolicy='${referrerPolicy}'${srcsetString} src='${url}' width='${chartSize.width}' height='${chartSize.height}' />`,
    ...chartSize,
  }
}

export function getSrcSet(url: string) {
  // You must keep a space after , for refresh to work
  return `${url} 1x, ${url}&sf=2 2x`
}
